import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";

import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation
} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import Mint from './Mint';
import Connect from '../components/Connect';
import Explorer from './Explorer';
import Admin from './Admin';
import Disclaimer from './Disclaimer';
import Test from './Test';

export default function Indexer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tab, setTab] = useState(0); //0: Wallet 1: Mint Domain 2: Explorer
  
  const handleTab = (tab) => {
    setTab(tab);
    switch(tab) {
      case 0:
        navigate('/');
        break;
    }
  }
  return (
    <div className="flex flex-col gap-5 bg-background items-center w-full">
      {
        location.pathname == '/disclaimer' ? 
          <></> 
          : 
          <div className="flex flex-row-reverse z-[9] w-full mt-5 pr-5">
            <Connect />
          </div>
      }
      <Routes>
        <Route path="/" element={<Mint />} />
        <Route path="/index" element={<Explorer />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/__test" element={<Test />} />
      </Routes>
    </div>
  )
}
