import React, { useState, useEffect } from 'react'
import { getAdminData, saveAdminData } from '../util/api'
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';

import { 
  Switch, 
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Select, 
  Option
} from "@material-tailwind/react";

import { fetchImageAsHex } from '../util/inscribe-util';

export default function Admin() {

  const wallet = useSelector(state => state.wallet);

  const [status, setStatus] = useState(false);
  const [address, setAddress] = useState("");
  const [count, setCount] = useState(0);
  const [start, setStart] = useState("");
  const [startMonth, setStartMonth] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [active, setActive] = useState(false);
  const [userText, setUserText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [loginAddress, setLoginAddress] = useState("");

  const months = ['9', '10', '11', '12'];
  const dates = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
  const times = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

  const userId = "eyes";
  const password = "eyes123";

  // useEffect(() => {
  //   const storedUser = localStorage.getItem('user');
  //   if (storedUser) {
  //     const userObject = JSON.parse(storedUser);
  //     if (userObject.userId == userId && userObject.password == password) {
  //       setActive(true);
  //     }
  //   }
  // })

  useEffect(() => {
    fetchAdminData();
  }, [wallet.nostrOrdinalsAddress])

  const convertTo12HourFormat = (hour) => {
    let period = hour >= 12 ? 'PM' : 'AM';
    if (hour === 0) {
        hour = 12; // Midnight case
    } else if (hour > 12) {
        hour -= 12; // Convert hour greater than 12 to 12-hour format
    }
    return `${hour} ${period}`;
  }

  const fetchAdminData = async () => {
    let response = await getAdminData();
    if (wallet.nostrOrdinalsAddress == response.loginAddress) {
      setActive(true);
    }
    else {
      setActive(false);
      toast.error("Wallet address does not match!");
    }
    setAddress(response.address);
    setCount(response.count);
    setStatus(response.status == 1 ? true : false);
    setStart(response.start);
    setLoginAddress(response.loginAddress);
    setStartMonth(response.start.split('-')[0]);
    setStartDate(response.start.split('-')[1]);
    setStartTime(response.start.split('-')[2]);
  }

  const handleSave = async () => {
    const data = {
      address: address,
      count: count,
      status: status ? 1 : 0,
      start: startMonth + "-" + startDate + "-" + startTime,
      loginAddress: loginAddress
    }
    await saveAdminData(data);
    toast.success("Successfully updated!");
    if (loginAddress != wallet.nostrOrdinalsAddress) setActive(false);
  }

  // const updateLocalStorage = () => {
  //   if (userText == userId && passwordText == password) {
  //     const user = {
  //       userId: userText,
  //       password: passwordText
  //     };
  //     localStorage.setItem('user', JSON.stringify(user));
  //     setActive(true);
  //   }
  //   else {
  //     toast.error("Credential does not correct!");
  //   }
  // }

  const handleStatus = () => {
    setStatus(!status);
  }

  const handleCount = (e) => {
    setCount(e.target.value);
  }

  const handleAddress = (e) => {
    setAddress(e.target.value);
  }

  const handleStartMonth = (value) => {
    setStartMonth(value);
  }

  const handleStartDate = (value) => {
    setStartDate(value);
  }

  const handleStartTime = (value) => {
    setStartTime(value);
  }

  const handleLoginAddress = (e) => {
    setLoginAddress(e.target.value);
  }


  return (
    <div className="flex flex-col gap-5 mt-10 sm:w-[600px] w-full z-[9999]">
      {
        active ? 
          <div className="text-red-800 font-bold text-[36px] text-center mb-10">Admin Panel</div>
          :
          <></>
      }
      {
        active ? 
        <div className = "flex flex-col gap-5 sm:w-[600px] w-full p-2">
          <div className="flex flex-row gap-5 items-center sm:justify-start justify-center w-full">
            <div className="text-white font-bold sm:text-[24px] text-[20px] mr-10 sm:w-[200px]">Mint Status</div>
            <Switch color="red" checked = {status} onChange={handleStatus}/>
            <div className="text-red-800 font-bold text-[24px]">{status ? 'Start' : 'Stop'}</div>
          </div>
          <div className="flex flex-row gap-5 items-center sm:justify-start justify-center w-full">
            <div className="sm:flex hidden text-white font-bold text-[24px] mr-10 w-[200px]">Limit per wallet</div>
            <div className="w-[300px]">
              <Input type="number" color="white" label = "Limit Per Wallet" className="w-full min-w-[300px]" value={count} onChange = {handleCount}/>
            </div>
          </div>
          <div className="flex flex-row gap-5 items-center sm:justify-start justify-center w-full">
            <div className="sm:flex hidden text-white font-bold text-[24px] mr-10 w-[200px]">Wallet Address</div>
            <div className="w-[300px]">
              <Input type="text" color="white" label = "Insert Payment Receiver Address" className="w-full min-w-[300px]" value={address} onChange = {handleAddress}/>
            </div>
          </div>
          <div className="flex flex-row gap-5 items-start sm:justify-start justify-center w-full">
            <div className="sm:flex hidden text-white font-bold text-[24px] mr-10 w-[200px]">Start Date</div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-6">
                <div className="text-white font-bold text-[20px]">Month: </div>
                <Select 
                  variant="static" 
                  className="text-white"
                  value={startMonth}
                  onChange={handleStartMonth}
                >
                  {
                    months.map((data) => 
                      <Option value = {data} key={uuidv4()}>{data}</Option>
                    )
                  }
                </Select>
              </div>
              <div className="flex flex-row items-end gap-6">
                <div className="text-white font-bold text-[20px] mr-[20px]">Date: </div>
                <Select 
                  variant="static" 
                  className="text-white"
                  value={startDate}
                  onChange={handleStartDate}
                >
                  {
                    dates.map((data) => 
                      <Option value = {data} key={uuidv4()}>{data}</Option>
                    )
                  }
                </Select>
              </div>
              <div className="flex flex-row items-end gap-6">
                <div className="text-white font-bold text-[20px] mr-[20px]">Time: </div>
                <Select 
                  variant="static" 
                  className="text-white"
                  value={startTime}
                  onChange={handleStartTime}
                >
                  {
                    times.map((data) => 
                      <Option value = {data} key={uuidv4()}>{convertTo12HourFormat(parseInt(data))}</Option>
                    )
                  }
                </Select>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-5 items-center sm:justify-start justify-center w-full">
            <div className="sm:flex hidden text-white font-bold text-[24px] mr-10 w-[200px]">Login Address</div>
            <div className="w-[300px]">
              <Input type="text" color="white" label = "Insert Login Address" className="w-full min-w-[300px]" value={loginAddress} onChange = {handleLoginAddress}/>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <Button className="mt-5 sm:w-full w-[300px]" color="red" onClick={handleSave}>Save</Button>
          </div>
        </div>
        :
        <div className="flex flex-col gap-5 sm:w-[600px] w-full p-2">
          <div className=" text-red-900 font-bold sm:text-[32px] text-[24px] text-center w-full">Please connect wallet first!</div>
          {/* <div className="flex flex-row gap-5 items-center sm:justify-start justify-center w-full">
            <div className="sm:flex hidden text-white font-bold text-[24px] mr-10 w-[200px]">User Id</div>
            <div className="w-[300px]">
              <Input type="text" color="white" label = "Insert user id" className="w-full min-w-[300px]" value={userText} onChange = {(e) => {setUserText(e.target.value)}}/>
            </div>
          </div>
          <div className="flex flex-row gap-5 items-center sm:justify-start justify-center w-full">
            <div className="sm:flex hidden text-white font-bold text-[24px] mr-10 w-[200px]">Password</div>
            <div className="w-[300px]">
              <Input type="password" color="white" label = "Insert password" className="w-full min-w-[300px]" value={passwordText} onChange = {(e) => {setPasswordText(e.target.value)}}/>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <Button className="mt-5 sm:w-full w-[300px] text-center" color="red" onClick={updateLocalStorage}>LOGIN</Button>
          </div> */}
        </div>
      }
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
