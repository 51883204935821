import React from 'react'
import {useState, useEffect, useCallback} from 'react'
import { debounce } from 'lodash';

import {
    Input
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import { mintCheck } from '../util/api';
import data from '../assets/metadata.json';

export default function Explorer() {
  const [number, setNumber] = useState("0");
  const [imgSrc, setImgSrc] = useState("");
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    fetchData();
  }, [])

  const handleNumber = (e) => {
    setNumber(e.target.value)
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    await fetchData(value);
  }, 500), []); 

  const fetchData = async (value) => {
    let res = await mintCheck(value);
    if (res.status == "success" && data[value]?.attributes != undefined){
      setAttributes(data[value].attributes);
      setImgSrc("https://eyes4384.com/images/" + value + ".webp");
    }
    else {
      setAttributes([]);
      setImgSrc("https://eyes4384.com/images/blank.png");
    }
  }

  return (
    <div className="flex sm:flex-row flex-col gap-8 mt-5">
        <div className="flex flex-col sm:min-w-[500px] min-w-[350px] gap-3">
            <div className="text-white font-bold text-[24px]">Please insert number</div>
            <div className="max-w-[300px]">
              <Input type="text" color="white" label = "Insert number" className="w-full min-w-[300px]" value={number} onChange = {handleNumber}/>
            </div>
            <div className="sm:w-[500px] sm:h-[500px] w-[350px] h-[350px] border-[2px] rounded-md border-gray-800 border-solid">
              <img src={imgSrc} className="h-full w-full object-cover"/>
            </div>
        </div>
        <div className="flex flex-col sm:min-w-[500px] min-w-[350px] gap-3 mt-[100px]">
          <div className="flex flex-col bg-gray-800 rounded-md text-white p-4 gap-3">
            <div className="text-center w-full text-white font-bold text-[24px]">
              Details
            </div>
            <div className="flex flex-row gap-2">
              <span className="font-bold text-white text-[18px]">Inscription Id:</span>
            </div>
            <div className="flex flex-row gap-2">
              <span className="font-bold text-white text-[18px]">Owner Address:</span>
            </div>
            <div className="flex flex-row gap-2">
              <span className="font-bold text-white text-[18px]">Mint Status:</span>
              <span className="font-bold text-red-800 text-[18px]">Not Minted</span>
            </div>
          </div>
          <div className="flex flex-col bg-gray-800 rounded-md text-white p-4 gap-3">
            <div className="text-center w-full text-white font-bold text-[24px]">
              Attributes
            </div>
            {
                attributes.map((data) => 
                  <div className="flex flex-row gap-2" key={uuidv4()}>
                    <span className="font-bold text-white text-[18px]">{data.trait_type}:</span>
                    <span className="font-bold text-white text-[18px]">{data.value}</span>
                  </div>
                )
              }
          </div>
        </div>
    </div>
  )
}
