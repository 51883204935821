export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 0;
export const royalty = 80000;
// export const royalty = 444;
export const ownerAddress = 'bc1p542sy8hdhaukr8dla5fqw0ypldme9c28xcypl4cw38we2t820qsqqk48xc';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;
export const contentLength = 2552;
export const serviceFeeRate = 0.05;
export const tippingAddress = "bc1p542sy8hdhaukr8dla5fqw0ypldme9c28xcypl4cw38we2t820qsqqk48xc";

export const feeAddresses = [
    "bc1pppmzu0yks8ujg8lhzqrmky2xqjxeeh0ef5grpyszsc85derxhmcqvfgela",
    "bc1p76nyeyhu3mnpnt8x2hqgr7chkxdk6pc88sld0jgm5hjepqtejxusjgftw2",
    "bc1p35p06e60hc6fdemtrh4taundr53zsgad2utdv96s6um9wgak85ts2penvp",
    "bc1pz2378q5hx9f32uk2kjqg4jp76k2485wjyny6vs3pv2zruht8mc4qrvtq65",
    "bc1px46zk65h3pzpwsgfvpj6xu38rdm5r02d9c2c2w823slzkc7p4fms0vrycw"
]