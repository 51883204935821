import React from 'react'

export default function Disclaimer() {
  return (
    <div className="w-full h-full bg-black text-red-900 z-[9999] flex flex-row justify-center">
        <div className="max-w-[1500px] flex flex-col gap-5 w-full py-[60px] sm:px-[60px] px-6">
            <div className="text-[32px] text-red-900 font-bold w-full">
                The Eyes Disclaimer 
            </div>
            <div className="flex flex-col gap-3 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Introduction
                </div>
                <div className=" w-full text-justify">
                    Welcome to The Eyes. The Site, operated by The Eyes ("The Eyes," "we," "us," or "our"), provides a platform for acquiring or contributing to digital artwork as Ordinals on the Bitcoin blockchain ("Works"). Do not access and use the site if you do not agree to the terms of this disclaimer. By using this website or its services, you acknowledge that you have thoroughly read and also understand the terms of this disclaimer and hereby agree to be bound thereof.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Definition of Terms
                </div>
                <div className=" w-full text-justify">
                    For the purposes of this Website Disclaimer, the following terms shall have the meanings ascribed to them below:
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Website</b>" or "<b>Site</b>" refers to any content, materials, and services provided by The Eyes, accessible to Website Visitors.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Content</b>" means any text, images, video, audio, or other multimedia content, software, or other information or material submitted to, served on, or accessible through the Website.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>The Eyes</b>" refers to the Artwork Creator and owner of the Website, providing various artworks and related information to Website Visitors.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Website Visitor</b>" or "<b>You</b>" means any individual or entity that accesses or uses the Website, whether or not they have created an account on the Website.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Ordinal or Ordinals</b>" means Bitcoin or other cryptocurrency assets that have had data or artworks inscribed on individual satoshis on the Bitcoin or other cryptocurrency blockchain. 
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-3 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Nature of the Artwork
                </div>
                <div className=" w-full text-justify">
                    The Works displayed on the Website is the creation of The Eyes and represents a form of expression and artistic endeavor. Website Visitors acknowledge that the nature of the Artwork is subjective and may be interpreted in various ways depending on the viewer's perspective. The Eyes make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the digital artwork for any purpose.
                </div>
                <div className=" w-full text-justify">
                    Any reliance placed on such information is therefore strictly at the Website Visitor's own risk. In no event will The Eyes be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the digital artwork on the Website.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Licensing
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">License Grant</div>
                    <div className=" w-full text-justify">
                        Upon acquiring or contributing to the Works, you are granted a non-exclusive, perpetual, royalty-free, non-transferable, and worldwide license, subject to the following terms:
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Personal Use Rights: </b>" You are permitted to display, copy, distribute, and create derivative works from the Works for both personal and commercial purposes. All copyrights in any derivative work remain with The Eyes.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Resale Rights: </b>" You may sell or transfer the digital artwork. Upon such a transaction, the license and associated rights transfer to the new owner.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        "<b>Usage Restrictions: </b>" You must not modify, obscure, or misrepresent the Works in any manner. The use of The Eyes’ trademarks is limited to identifying the collection and must not imply ownership of different Works.
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Prohibition on Sublicensing</div>
                    <div className=" w-full text-justify">
                        You may not sublicense, delegate, or otherwise transfer your rights to the Works independently of transferring the digital artwork itself. Any attempt to do so will result in the termination of your rights.
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Assumption of Risk 
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Contributions</div>
                    <div className=" w-full text-justify">
                        Please be advised that the Works are not registered securities, financial instruments, or investment products. They are solely intended for personal enjoyment and aesthetic appreciation. The following key points should be noted:
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        No Investment Offering: The Works are not offered as investments and should not be construed as such. The purchase of digital artwork is intended exclusively for personal enjoyment, collecting, and aesthetic purposes.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Not Registered Securities: The Works are not subject to registration or regulation under securities laws or financial regulations. They do not offer any financial returns, dividends, or profit-sharing opportunities.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Speculative Value: The value of the Works is subjective and can fluctuate based on personal preferences, trends, and market conditions. There are no guarantees regarding the value or future appreciation of the Works.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        No Financial Return: The contribution to digital artwork does not involve any expectation of financial gain or investment returns. The value of the Works is not linked to financial markets or investment performance.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Personal Enjoyment: The Works are purchased for their artistic and aesthetic value. Any purchase should be made with the understanding that it is for personal collection and enjoyment, not for financial profit.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Lack of Regulatory Oversight: The Works are not subject to oversight by financial regulators. Their sale and purchase are not governed by securities laws or investment regulations.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        No Investment Advice: This communication does not constitute investment advice or a recommendation to purchase the Works as an investment. It is meant solely to inform that the Works are for personal enjoyment.
                    </div>
                </div>
                <div className=" w-full text-justify">
                    By acquiring or contributing to Works, buyers acknowledge and agree that they are contributing to a creative art project, with no expectation of financial return or investment benefits. For any questions regarding the nature of Artworks or the purchase process, please consult with relevant advisors or seek additional information as needed.
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Value</div>
                    <div className=" w-full text-justify">
                        The Works are unique and diverse forms of art that may involve various styles, mediums, and methods. Ordinals are not subject to the regulatory requirements of cryptocurrency and are primarily intended for personal enjoyment and appreciation rather than financial gain. There are several important considerations to be aware of:
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Speculative Nature: The value of the Works can be highly speculative and may fluctuate significantly. There is a risk that you could lose a substantial portion or all of the amount spent. Potential buyers should be prepared to bear the risks associated with purchasing the Works.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Conflicts of Interest: There may be conflicts of interest related to the sale or representation of the Works.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Transparency: Information about the provenance, value, or market trends of the Works may not always be fully transparent or available.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Liquidity: The Works may not be easily resold or transferred. There is no secondary market guaranteed for the resale of the Works.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Costs: The purchase of the Works may involve significant costs and expenses, which should be considered as part of the buying decision.
                    </div>
                </div>
                <div className=" w-full text-justify">
                    The above summary is for informational purposes only and does not constitute financial advice or an investment recommendation. Buyers should review relevant details and consult with knowledgeable advisors if needed to ensure that their purchase of Artworks meets their personal preferences and objectives.
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Technology</div>
                    <div className=" w-full text-justify">
                        By using our Services, you acknowledge and accept the inherent risks associated with engaging in transactions that rely on smart contracts and other experimental technologies. These transactions are dependent on smart contracts stored on various blockchains, cryptographic tokens generated by the smart contracts, and other nascent software, applications and systems that interact with blockchain-based networks. These technologies are experimental, speculative, inherently risky, and subject to change. This means that bugs, malfunctions, cyberattacks, or changes to the applicable blockchain (e.g., forks) could disrupt these technologies and potentially result in a total loss of digital assets, their market value, or digital funds. It is your sole responsibility to safeguard the private key associated with the blockchain address used to interact with our Site. We hold no liability or responsibility for any of these risks. If you are not comfortable with these risks, it is advised that you do not access or engage in transactions using blockchain-based technology. It is important to note that one of the defining features of blockchain technology is its immutability, which means that entries, including smart contracts and digital assets generated by them, cannot be deleted or modified by anyone. This includes transactions recorded on the blockchain, such as transfers of digital assets and data programmed into these assets (such as revenue and interest allocations), which must be treated as permanent and non-negotiable. Therefore, it is crucial that you exercise caution when finalizing any transaction that will be recorded on the blockchain. Please be aware that our Services are provided on the blockchain, and as such, all transactions are finalized and closed out immediately.
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    User Obligations
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Security Measures</div>
                    <div className=" w-full text-justify">
                        You are solely responsible for safeguarding your account credentials and wallet information. We disclaim any responsibility for unauthorized access or transactions involving your account.
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Prohibited Conduct</div>
                    <div className=" w-full text-justify">
                        You agree to refrain from:
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Altering, adapting, duplicating, decoding, disassembling or reverse engineering any aspect of the Site.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Interfering with the Site’s functionality or other users’ experiences including using or attempting to use another users’ account. 
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        Imposing an excessive burden on our infrastructure.
                    </div>
                </div>
                <div className="flex flex-row w-full text-justify">
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-justify">
                        This site is unfit for children. Anyone under the age of 18 does not have license to utilize the Site without parental supervision and authority. 
                    </div>
                </div>
                <div className=" w-full text-justify">
                    By accessing this Site, you understand that The Eyes reserves the right to restrict access and terminate accounts for violations of these Terms or other misconduct.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Transactions and Payments
                </div>
                <div className=" w-full text-justify">
                    Contributions on this Site are processed through the Bitcoin network, third-party payment processors, or other methods at Our sole discretion. Prices will be as indicated on the purchase landing page. We are not responsible for transaction errors or failures and do not have control over the processing of payments. You agree to assume the risk of making any such transactions, whether conducted through the Bitcoin network or otherwise. We will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you conducted via the Bitcoin network. <b>EXCEPT AS REQUIRED BY LAW, ALL THE EYES PURCHASES ARE FINAL AND NON-REFUNDABLE. NO REFUNDS, RETURNS OR EXCHANGES WILL BE PERMITTED.</b>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Age Restriction
                </div>
                <div className=" w-full text-justify">
                    By accessing the Site, you affirm that you meet this age requirement. No individuals under the age of eighteen (18) shall access the Site or contribute to any Works. 
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Disclaimers
                </div>
                <div className=" w-full text-justify">
                    Your access of this Site and any contribution to the Works is at your own risk. The Site is provided "as is," and The Eyes expressly disclaims any express or implied warranties. The Eyes does not guarantee uninterrupted or error-free operation of the Site. 
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Limitation of Liability 
                </div>
                <div className=" w-full text-justify">
                    EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY THE EYES, THE SITE, THE WORKS, AND ALL CONTENT CONTAINED THEREIN ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE EYES DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT AS TO THE SITE, THE CONTENT, AND THE WORKS. IN NO EVENT SHALL THE EYES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE SITE, THE WORKS, THE USE OF THE WORKS OR THE CONTENT CONTAINED THEREIN, REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF THE EYES, EXCEPT IN THE CASE OF GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Intellectual Property 
                </div>
                <div className=" w-full text-justify">
                    The Eyes’ name, logo, trademarks, all Works herein, and any The Eyes product or service names, designs, logos, and slogans are the intellectual property of The Eyes and may not be copied, imitated or used, in whole or in part, without our prior written permission in each instance. Reference to any products, services, processes or other information by name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by The Eyes.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Third-Party Links
                </div>
                <div className=" w-full text-justify">
                    The Website may contain links to third-party websites or resources. These links are provided solely as a convenience to our Website Visitors and not as an endorsement of the contents on such third-party websites. The Eyes does not control, and is not responsible for, any of these external sites or their content. Accordingly, The Eyes makes no representations or warranties of any kind regarding the content or accuracy of materials on such third-party websites.
                </div>
                <div className=" w-full text-justify">
                    Website Visitors acknowledge and agree that The Eyes shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party sites or resources. It is the responsibility of the Website Visitor to evaluate the content and usefulness of the information obtained from other sites.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Indemnification
                </div>
                <div className=" w-full text-justify">
                    You agree to indemnify, defend, and hold harmless The Eyes and its affiliates from any claims, damages, losses, or expenses arising out of your use of the Site, breach of this Disclaimer, or violation of applicable laws.
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Governing Law and Dispute Resolution
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Arbitration</div>
                    <div className=" w-full text-justify">
                        Any dispute, claim, question, or disagreement arising from or relating to this Site or its Terms or the breach thereof, shall be resolved by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The place of arbitration shall be the State of Florida. The arbitration shall be governed by the laws of the State of Florida. The parties agree to submit to the exclusive jurisdiction of the Federal located in the State of Florida, to compel arbitration, to enforce any arbitration award, or to seek injunctive or equitable relief.
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    <div className="w-full font-bold">Court Jurisdiction</div>
                    <div className=" w-full text-justify">
                        If arbitration is not applicable, disputes must be resolved in federal court located in the State of Florida. You agree to the exclusive jurisdiction and venue of such courts.
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <div className="text-[24px] text-red-900 font-semibold w-full">
                    Contact Information
                </div>
                <div className=" w-full text-justify">
                    For inquiries or to initiate arbitration, please contact The Eyes at: 
                </div>
                <div className=" w-full text-justify">
                    Dated: September 10, 2024
                </div>
            </div>
        </div>
    </div>
  )
}
